export const messages: { [key: string]: Record<string, string> } = {
  en: {
    "CalculatorTitle.Title": "Loan calculator",
    "CalculatorTitle.Disclaimer":
      "Your bank may use a different algorithm to calculate your payments.",
    "AmountInput.Amount": "Amount",
    "DurationInput.Term": "Term",
    "DurationInput.Month": "months",
    "Feedback.Title": "Feedback",
    "Feedback.DateTimeToolbox": "Date and Time Toolbox",
    "FirstPayDate.StartDate": "Start Date",
    "Info.PrivacyTitle": "Privacy",
    "Info.ManualTitle": "Manual",
    "Info.PrivacyContent": `
          Everything is calculated in your browser.
          <br></br>
          If your browser allows it, usage statistics are recorded.
          `,
    "Info.ManualContent": `
          <ul>
            <li>
              Starting cost or any other fees (mortgage fee, appraisal etc.) are not included.
            </li>
            <li>
              This calculator is suitable for <aen>annuity loans</aen> only. (Fixed, monthly payments)
            </li>
            <li>
              Prepayment: click on the 💰 in the row of the month, when the prepayment was done.
              You can choose whether you want to decrease the term and keep paying the same monthly payment or 
              keep the term and decrease the monthly payment.
            </li>
            <li>
              Rate change: click on the 📈 in the row of the month, from which the new rate takes effect.
            </li>
            <li>
              Comparison: click on the 🆚 to set the baseline and start chaning the parameters (term, amount etc).
              The difference between the baseline and the current value is displayed in parentheses. 
            </li>
            <li>
              Pin to top: click on the 📌 to stick a parameter to the top of the screen. 
              It remains visible even if you scroll down.
            </li>
          </ul>
    `,
    "Installment.MonthlyPayment": "Monthly payment",
    "Installment.SetForCompare": "Select for comparison",
    "Installment.TotalInterest": "Total Interest",
    "Installment.Capital": "Capital",
    "Installment.Interest": "Interest",
    "PaymentTable.Date": "Date",
    "PaymentTable.Payment": "Payment",
    "PaymentTable.Principal": "Principal",
    "PaymentTable.Interest": "Interest",
    "PaymentTable.Balance": "Balance",
    "PaymentTable.InterestRateChange": "Interest rate change",
    "PaymentTable.InterestRate": "Interest rate: ",
    "PaymentTable.EditPrepayment": "Edit prepayment",
    "PaymentTable.RemovePrepayment": "Remove prepayment",
    "PaymentTable.EditRateChange": "Edit rate change",
    "PaymentTable.RemoveRateChange": "Remove rate change",
    "PaymentTable.Prepayment": "Prepayment",
    "PinnableInput.Title": "Pin to top",
    "PinnableInput.Target": "Recalculate on parameter change",
    "PrepaymentModification.Label": "Prepayment amount",
    "PrepaymentModification.DecreasePayment": "Decrease monthly payment",
    "PrepaymentModification.DecreaseTerm": "Decrease term",
    "Common.Cancel": "Cancel",
    "Common.Save": "Save",
    "RateChange.NewRate": "New rate",
    "RateInput.Rate": "Interest rate",
    "RateInput.Info": " ",
    "Footer.Updated": "Last update",
  },
  hu: {
    "CalculatorTitle.Title": "Hitel kalkulátor",
    "CalculatorTitle.Disclaimer":
      "A kalkuláció eredménye minden esetben tájékoztató jellegű (a banki számításoktól eltérhet)!",
    "AmountInput.Amount": "Hitelösszeg",
    "DurationInput.Term": "Futamidő",
    "DurationInput.Month": "hónap",
    "Feedback.Title": "Visszajelzés",
    "Feedback.DateTimeToolbox": "Munkanap kalkulátor",
    "FirstPayDate.StartDate": "Törlesztés kezdete",
    "Info.PrivacyTitle": "Adatvédelem",
    "Info.ManualTitle": "Leírás",
    "Info.PrivacyContent": `
          A kalkulátor minden számítást a böngészőben végez el.
          <br></br>
          Az oldal fejlesztése érdekében látogatottsági és használati adatokat
          is gyűjtök, amennyiben a böngésződ ezt engedélyezi.
          `,
    "Info.ManualContent": `
          <ul>
            <li>
              Ez a kalkulátor nem veszi figyelembe a hitelek egyszeri költségeit
              (folyósítási díj, közjegyző, előtörlesztési díj,
              szerződésmódosítás díja stb.).
            </li>
            <li>
              Csak olyan hitelek esetén használható, ahol a törlesztőrészlet
              állandó (ún. 
              <ahu>
                annuitásos
              </ahu>
              hitelek).
            </li>
            <li>
              Előtörlesztés: kattints a 💰-ra annak a hónapnak a sorában, amikor
              az előtörlesztés történt. Kiválszthatod, hogy az előtörlesztés
              következményeként a futamidő csökkenjen változatlan
              törlesztőrészlet mellett vagy a törlesztőrészlet csökkenjen
              változatlan futamidő mellett.
            </li>
            <li>
              Kamatváltozás: kattints a 📈-ra annak a hónapnak a sorában
              amikortól az új kamat érvényes!
            </li>
            <li>
              Összehasonlítás: kattints a 🆚-re, ha látni szeretnéd, hogy
              mennyivel nő a teljes kamat vagy a törlesztőrészlet, miközben
              változtatod a paramétereket.
            </li>
            <li>
              Paraméter kiemelése: ha a 📌-re kattintasz, az adott paraméter
              mindig az oldal tetején fog megjelenni. Így az oldal többi részét
              szabadon görgetheted.
            </li>
          </ul>
    `,
    "Installment.MonthlyPayment": "Havi törlesztőrészlet",
    "Installment.SetForCompare": "Összehasonlítási alap beállítása",
    "Installment.TotalInterest": "Teljes kamat",
    "Installment.Capital": "Tőke",
    "Installment.Interest": "Kamat",
    "PaymentTable.Date": "Dátum",
    "PaymentTable.Payment": "Törlesztés",
    "PaymentTable.Principal": "Tőke",
    "PaymentTable.Interest": "Kamat",
    "PaymentTable.Balance": "Tőketartozás",
    "PaymentTable.InterestRateChange": "kamatváltozás",
    "PaymentTable.InterestRate": "Kamatláb: ",
    "PaymentTable.EditPrepayment": "Előtörlesztés szerkesztése",
    "PaymentTable.RemovePrepayment": "Előtörlesztés törlése",
    "PaymentTable.EditRateChange": "Kamatváltozás szerkesztése",
    "PaymentTable.RemoveRateChange": "Kamatváltozás törlése",
    "PaymentTable.Prepayment": "Előtörlesztés",
    "PinnableInput.Title": "Rögzítés az ablak tetejére",
    "PinnableInput.Target": "Újraszámítás paraméterváltozás esetén",
    "PrepaymentModification.Label": "Előtörlesztés összege",
    "PrepaymentModification.DecreasePayment": "Törlesztőrészlet csökkentése",
    "PrepaymentModification.DecreaseTerm": "Futamidő csökkentése",
    "Common.Cancel": "Mégsem",
    "Common.Save": "Mentés",
    "RateChange.NewRate": "Új kamatláb",
    "RateInput.Rate": "Kamatláb",
    "RateInput.Info":
      "Ügyleti kamatlábnak is hívják. Értéke általában kisebb, mint THM.",
    "Footer.Updated": "Frissítve",
  },
};
