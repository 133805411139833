import * as React from "react";
import { useIntl } from "react-intl";
import { changeInstallment, useAppDispatch, useAppSelector } from "../store";
import { getMaxMonthlyPayment } from "../utils";
import PinnableInput from "./PinnableInput";

export default function InstallmentInput() {
  const installment = useAppSelector(state => state.installment);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const lang = useAppSelector((state) => state.lang);

  function change(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(changeInstallment(Number(e.target.value)));
  }

  return (
    <PinnableInput
      label={intl.formatMessage({ id: "Installment.MonthlyPayment" })}
      inputId="installment"
    >
      <input
        id="installment"
        type="number"
        className="form-control"
        aria-label={intl.formatMessage({ id: "Installment.MonthlyPayment" })}
        min="1"
        value={installment}
        onChange={change}
      />
      {lang === "hu" && (
        <div className="input-group-append">
          <span className="input-group-text">Ft</span>
        </div>
      )}
      <input
        value={installment}
        className="form-control"
        type="range"
        min="1"
        max={getMaxMonthlyPayment(lang)}
        style={{ width: "100%" }}
        onChange={change}
      />
    </PinnableInput>
  );
}
