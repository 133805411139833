import * as React from "react";
import { useIntl } from "react-intl";
import { amountSelector } from "../selector";
import { changeAmount, useAppDispatch, useAppSelector } from "../store";
import PinnableInput from "./PinnableInput";

export default function AmountInput() {
  const amount = Math.round(useAppSelector(amountSelector));
  const dispatch = useAppDispatch();
  const lang = useAppSelector((state) => state.lang);
  const intl = useIntl();
  const hu = lang === "hu";

  return (
    <PinnableInput
      label={intl.formatMessage({ id: "AmountInput.Amount" })}
      inputId="amount"
    >
      <input
        id="amount"
        type="number"
        className="form-control"
        placeholder="123..."
        aria-label={intl.formatMessage({ id: "AmountInput.Amount" })}
        min="0"
        step={hu ? "1000" : "1"}
        value={amount}
        onChange={(e) => dispatch(changeAmount(Number(e.target.value)))}
      />
      {hu && (
        <div className="input-group-append">
          <span className="input-group-text">Ft</span>
        </div>
      )}
    </PinnableInput>
  );
}
