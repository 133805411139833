import cl from "classnames";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import FullCenteredRow from "./FullCenteredRow";

export interface Props {}

export default function Info(props: Props) {
  const [privacyInfoShow, setPrivacyInfoShow] = useState<boolean>(false);
  const [descriptionShow, setDescriptionShow] = useState<boolean>(false);

  function showPrivacyInfo() {
    setDescriptionShow(false);
    setPrivacyInfoShow((old) => !old);
  }

  function showDescription() {
    setPrivacyInfoShow(false);
    setDescriptionShow((old) => !old);
  }

  return (
    <div>
      <FullCenteredRow cols={6}>
        <a
          href="#"
          aria-expanded="false"
          aria-controls="privacyInfo"
          onClick={showPrivacyInfo}
        >
          <FormattedMessage id="Info.PrivacyTitle" />
        </a>
        &nbsp;-&nbsp;
        <a
          href="#"
          aria-expanded="false"
          aria-controls="description"
          onClick={showDescription}
        >
          <FormattedMessage id="Info.ManualTitle" />
        </a>
      </FullCenteredRow>
      <FullCenteredRow cols={7} center={false}>
        <div
          id="privacyInfo"
          className={cl("alert alert-info text-center", {
            "collapse.show": privacyInfoShow,
            collapse: !privacyInfoShow,
          })}
          role="alert"
        >
          <FormattedMessage id="Info.PrivacyContent" />
        </div>
      </FullCenteredRow>
      <FullCenteredRow cols={7} center={false}>
        <div
          id="description"
          className={cl("alert alert-info", {
            "collapse.show": descriptionShow,
            collapse: !descriptionShow,
          })}
          role="alert"
        >
          <FormattedMessage
            id="Info.ManualContent"
            values={{
              aen: (chunks: React.ReactNode) => (
                <a
                  href="https://en.wikipedia.org/wiki/Annuity"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {chunks}
                </a>
              ),
              ahu: (chunks: React.ReactNode) => (
                <a
                  href="https://hu.wikipedia.org/wiki/Annuit%C3%A1s_(p%C3%A9nz%C3%BCgy)"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </div>
      </FullCenteredRow>
    </div>
  );
}
