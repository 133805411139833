import * as React from "react";
import { FormattedMessage } from "react-intl";
import { langSelector } from "../selector";
import { useAppSelector } from "../store";
import FullCenteredRow from "./FullCenteredRow";

export interface Props {}

export default function CalculatorTitle(props: Props) {
  const lang = useAppSelector(langSelector);
  return (
    <FullCenteredRow>
      <h1>
        <FormattedMessage id="CalculatorTitle.Title" />
        &nbsp;
        {lang === "hu" && (
        <small>
          <a href="https://loan.csuzdi.hu" lang="en" title="Switch to English">🇬🇧</a>
        </small>
        )}
      </h1>
      <small>
        <FormattedMessage id="CalculatorTitle.Disclaimer" />
      </small>
    </FullCenteredRow>
  );
}
