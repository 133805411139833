import React, { useEffect } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import CalculatorTitle from "./components/CalculatorTitle";
import Feedback from "./components/Feedback";
import FirstPayDate from "./components/FirstPayDate";
import Info from "./components/Info";
import Installment from "./components/Installment";
import MainData from "./components/MainData";
import PaymentTable from "./components/PaymentTable";
import SaveButton from "./components/SaveButton";
import { messages } from "./messages";
import { useAppSelector } from "./store";
import { useAnalytics } from "./utils";

const ANALYTICS_API_KEY = "73whZLlNr63donxerYY6F2G4cILZREaKmzDvt000";

function App() {
  const lang = useAppSelector((state) => state.lang);
  useAnalytics(ANALYTICS_API_KEY);

  return (
    <IntlProvider
      messages={messages[lang]}
      locale={lang}
      defaultLocale="en"
      defaultRichTextElements={{
        br: () => <br />,
        ul: (chunks) => <ul>{chunks}</ul>,
        li: (chunks) => <li>{chunks}</li>,
      }}
    >
      <div className="container">
        <CalculatorTitle />
        <Info />
        <MainData />
        <Installment />
        <FirstPayDate />
        <PaymentTable />
        <SaveButton />
        <div className="row mt-3">
          <div className="col-12 text-center">
            V4.1 <FormattedMessage id="Footer.Updated" />: 2023.10.30.
          </div>
        </div>
        <Feedback />
      </div>
    </IntlProvider>
  );
}

export default App;
