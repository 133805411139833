import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
import addMonths from "date-fns/addMonths";
import setDate from "date-fns/setDate";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { rateSelector } from "./selector";
import {
  calculateAmount,
  calculateDuration,
  calculateInstallment,
  calculateRate,
  getDefaultAmount,
} from "./utils";
import { parse } from "date-fns";

const LANG = process.env.REACT_APP_DEFAULT_LANGUAGE ?? "en";
console.log("Default language: ", LANG);

export type Target = "amount" | "duration" | "rate" | "installment";

export interface InterestRate {
  date: string;
  rate: number;
}

export interface Prepayment {
  date: string;
  amount: number;
  type: "duration" | "installment"; // which one to decrease
}

export interface RootState {
  amount: number;
  duration: number;
  installment: number;
  interestRates: InterestRate[];
  prepayments: Prepayment[];
  lang: string;
  target: Target; // which parameter to recalculate when changing one
}

function getInitialState(): RootState {
  let saved = localStorage.getItem("data");
  if (saved) {
    const parsedState = JSON.parse(saved);
    if (!parsedState.lang) {
      parsedState.lang = LANG;
    }
    if (!parsedState.target) {
      parsedState.target = "installment";
    }
    if (!parsedState.installment) {
      parsedState.installment = calculateInstallment(
        parsedState.amount,
        parsedState.duration,
        rateSelector(parsedState)
      );
    }
    return parsedState;
  }
  return {
    amount: getDefaultAmount(LANG),
    duration: 120,
    installment: calculateInstallment(getDefaultAmount(LANG), 120, 3.15),
    interestRates: [
      {
        date: setDate(addMonths(new Date(), 1), 15)
          .toISOString()
          .substring(0, 10),
        rate: 3.15,
      },
    ],
    prepayments: [],
    lang: LANG,
    target: "installment",
  };
}

function dateComparator(a: { date: string }, b: { date: string }) {
  if (a.date === b.date) {
    return 0;
  } else if (a.date < b.date) {
    return -1;
  } else {
    return 1;
  }
}

const slice = createSlice({
  name: "state",
  initialState: getInitialState(),
  reducers: {
    changeAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
      if (state.target === "amount") {
        state.target = "installment";
      }
      recalculate(state);
    },
    changeDuration: (state, action: PayloadAction<number>) => {
      state.duration = action.payload;
      if (state.target === "duration") {
        state.target = "installment";
      }
      recalculate(state);
    },
    changeInitialRate: (state, action: PayloadAction<number>) => {
      state.interestRates[0].rate = action.payload;
      if (state.target === "rate") {
        state.target = "installment";
      }
      recalculate(state);
    },
    changeStartDate: (state, action: PayloadAction<string>) => {
      state.interestRates[0].date = action.payload;
    },
    changePrepayment: (state, action: PayloadAction<Prepayment>) => {
      let date = action.payload.date;
      let prepayments = state.prepayments.filter((elem) => elem.date !== date);
      prepayments.push(action.payload);
      prepayments.sort(dateComparator);
      state.prepayments = prepayments;
    },
    changeRate: (state, action: PayloadAction<InterestRate>) => {
      const newRate = action.payload;
      let interestRates = state.interestRates.filter(
        (elem) => elem.date !== newRate.date
      );
      interestRates.push(newRate);
      interestRates.sort(dateComparator);
      state.interestRates = interestRates;
    },
    changeInstallment: (state, action: PayloadAction<number>) => {
      state.installment = action.payload;
      if (state.target === "installment") {
        state.target = "amount";
      }
      recalculate(state);
    },
    deletePrepayment: (state, action: PayloadAction<string>) => {
      state.prepayments = state.prepayments.filter(
        (elem) => elem.date !== action.payload
      );
    },
    deleteRate: (state, action: PayloadAction<string>) => {
      state.interestRates = state.interestRates.filter(
        (elem) => elem.date !== action.payload
      );
    },
    setTarget: (state, action: PayloadAction<Target>) => {
      state.target = action.payload;
    },
  },
});

export const store = configureStore({ reducer: slice.reducer });

export const {
  changeAmount,
  changeDuration,
  changeInitialRate,
  changeStartDate,
  changePrepayment,
  deletePrepayment,
  changeRate,
  deleteRate,
  changeInstallment,
  setTarget,
} = slice.actions;
export type Dispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<Dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function recalculate(state: RootState) {
  switch (state.target) {
    case "duration":
      state.duration = safeNum(
        calculateDuration(state.amount, rateSelector(state), state.installment)
      );
      break;
    case "installment":
      state.installment = safeNum(
        calculateInstallment(state.amount, state.duration, rateSelector(state))
      );
      break;
    case "rate":
      const newRate = safeNum(
        calculateRate(state.amount, state.duration, state.installment)
      );
      state.interestRates[0].rate = Math.round(newRate * 100) / 100;
      break;
    case "amount":
      state.amount = safeNum(
        calculateAmount(state.duration, rateSelector(state), state.installment)
      );
      break;
    default:
      throw new Error("Unknown target " + state.target);
  }
}

function safeNum(num: number) {
  if (isNaN(num)) {
    return 0;
  }
  return num;
}
