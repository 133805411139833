import cl from "classnames";
import * as React from "react";

export interface Props {
  cols?: number;
  center?: boolean;
  pinned?: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function FullCenteredRow({
  cols = 12,
  center = true,
  pinned = false,
  children,
  className,
}: Props) {
  return (
    <div
      className={cl(
        "row",
        {
          "fixed-top": pinned,
          "bg-light": pinned,
        },
        className
      )}
    >
      <div className="col"></div>
      <div
        className={cl(`col-lg-${cols} col-md-${cols + 1} col-sm-${cols + 2}`, {
          "text-center": center,
        })}
      >
        {children}
      </div>
      <div className="col"></div>
    </div>
  );
}
