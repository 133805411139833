import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { barPartsSelector, interestSelector, langSelector } from "../selector";
import { useAppSelector } from "../store";
import { formatCurrency } from "../utils";
import FullCenteredRow from "./FullCenteredRow";

export default function Installment() {
  const [baseInstallment, setBaseInstallment] = useState<number>();
  const [baseInterest, setBaseInterest] = useState<number>();
  const calculatedInstallment = useAppSelector((state) => state.installment);
  const interest = Math.round(useAppSelector(interestSelector));
  const parts = useAppSelector(barPartsSelector);
  const lang = useAppSelector(langSelector);
  const intl = useIntl();

  function baseInstallmentOnClick() {
    setBaseInstallment(Math.round(calculatedInstallment));
  }

  function baseInterestOnClick() {
    setBaseInterest(interest);
  }

  return (
    <div>
      <div className="row">
        <div className="col"></div>
        <div className="col-lg-3 col-md-4 col-sm-5">
          <label htmlFor="amount">
            <FormattedMessage id="Installment.MonthlyPayment" />
          </label>
          <span
            className="float-right"
            title={intl.formatMessage({ id: "Installment.SetForCompare" })}
            style={{ cursor: "pointer" }}
            onClick={baseInstallmentOnClick}
          >
            🆚
          </span>
          {baseInstallment && (
            <small className="float-right text-muted">
              {formatCurrency(lang, baseInstallment)}&nbsp;
            </small>
          )}
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">
              {formatCurrency(lang, Math.round(calculatedInstallment))}&nbsp;
              {baseInstallment && (
                <span className="h6">
                  (
                  {formatCurrency(
                    lang,
                    Math.round(calculatedInstallment - baseInstallment)
                  )}
                  )
                </span>
              )}
            </h4>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-5">
          <label htmlFor="amount">
            <FormattedMessage id="Installment.TotalInterest" />
          </label>
          <span
            className="float-right"
            title={intl.formatMessage({ id: "Installment.SetForCompare" })}
            style={{ cursor: "pointer" }}
            onClick={baseInterestOnClick}
          >
            🆚
          </span>
          {baseInterest && (
            <small className="float-right text-muted">
              {formatCurrency(lang, baseInterest)}&nbsp;
            </small>
          )}
          <div className="alert alert-primary" role="alert">
            <h4 className="alert-heading">
              {formatCurrency(lang, interest)}&nbsp;
              {baseInterest && (
                <span className="h6">
                  ({formatCurrency(lang, Math.round(interest - baseInterest))})
                </span>
              )}
            </h4>
          </div>
        </div>
        <div className="col"></div>
      </div>
      <FullCenteredRow cols={6}>
        <div id="bar" style={{ marginLeft: "5px", marginRight: "5px" }}>
          {parts.map((p, i) => (
            <div key={i} className={"barPart"} style={p.style} title={p.title}>
              {p.text}
            </div>
          ))}
        </div>
      </FullCenteredRow>
    </div>
  );
}
