import React from "react";
import { createSelector } from "reselect";
import { messages } from "./messages";
import { RootState } from "./store";
import { formatCurrency, payments } from "./utils";

interface BarPart {
  title: string;
  style: React.CSSProperties;
  text: string;
}

export const amountSelector = (state: RootState) => state.amount;
export const durationSelector = (state: RootState) => state.duration;
export const interestRatesSelector = (state: RootState) => state.interestRates;
export const prepaymentsSelector = (state: RootState) => state.prepayments;
export const langSelector = (state: RootState) => state.lang;

export const rateSelector = createSelector(
  interestRatesSelector,
  (rates) => rates[0].rate
);

export const firstDateSelector = createSelector(
  interestRatesSelector,
  (rates) => rates[0].date
);

export const paymentsSelector = createSelector(
  interestRatesSelector,
  prepaymentsSelector,
  durationSelector,
  amountSelector,
  (rates, prepayments, duration, amount) =>
    payments(rates, prepayments, duration, amount)
);

export const interestSelector = createSelector(paymentsSelector, (payments) =>
  payments
    .filter((p) => p.type === "payment")
    .reduce((acc, act) => acc + (act?.interestPayment ?? 0), 0)
);

export const barPartsSelector = createSelector(
  amountSelector,
  interestSelector,
  langSelector,
  (amount, interest, lang) => {
    const parts: BarPart[] = [],
      amountPercent = (amount / (amount + interest)) * 100,
      interestPercent = 100 - amountPercent;
    parts.push({
      title:
        messages[lang]["Installment.Capital"] +
        ": " +
        formatCurrency(lang, amount),
      style: {
        background: "#1f77b4",
        width: amountPercent + "%",
      },
      text: `${messages[lang]["Installment.Capital"]}: ${Math.round(
        amountPercent
      )}%`,
    });
    parts.push({
      title:
        messages[lang]["Installment.Interest"] +
        ": " +
        formatCurrency(lang, interest),
      style: {
        background: "#ff7f0e",
        width: interestPercent + "%",
      },
      text:
        interestPercent > 20
          ? `${messages[lang]["Installment.Interest"]}: ${Math.round(
              interestPercent
            )}%`
          : "",
    });
    return parts;
  }
);

export const storeJsonSelector = createSelector(
  (state: any) => state,
  (state: any) => JSON.stringify(state)
);
