import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { storeJsonSelector } from "../selector";
import FullCenteredRow from "./FullCenteredRow";

export default function SaveButton() {
  const stateJson = useSelector(storeJsonSelector);
  const [saved, setSaved] = useState<string | null>(
    localStorage.getItem("data")
  );

  function save() {
    localStorage.setItem("data", stateJson);
    setSaved(stateJson);
  }

  return (
    <FullCenteredRow>
      <button
        type="button"
        className="btn btn-primary"
        disabled={saved === stateJson}
        onClick={save}
      >
        <FormattedMessage id="Common.Save" />
      </button>
    </FullCenteredRow>
  );
}
