import { addMonths } from "date-fns/esm";
import lightFormat from "date-fns/lightFormat";
import * as React from "react";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { paymentsSelector, prepaymentsSelector } from "../selector";
import { changePrepayment, useAppSelector } from "../store";

export interface Props {
  date: string;
  hide: () => void;
}

export default function PrepaymentModification(props: Props) {
  const prepayments = useAppSelector(prepaymentsSelector);
  const payments = useAppSelector(paymentsSelector);
  const principal = useMemo(() => {
    const prevMonth = lightFormat(
      addMonths(new Date(props.date), -1),
      "yyyy-MM-dd"
    );
    return (
      payments.find((p) => p.date === prevMonth)?.principal ??
      payments.find((p) => p.date === props.date)?.principal
    );
  }, [props.date, payments]);
  const currentPrepayment = prepayments.find((p) => p.date === props.date);
  const [amount, setAmount] = useState<number>(currentPrepayment?.amount ?? 0);
  const [type, setType] = useState<"duration" | "installment">(
    currentPrepayment?.type ?? "installment"
  );
  const dispatch = useDispatch();
  const intl = useIntl();
  const lang = useAppSelector((state) => state.lang);

  function change(e: React.ChangeEvent<HTMLInputElement>) {
    setAmount(Number(e.target.value));
  }

  function addPrepayment() {
    props.hide();
    dispatch(changePrepayment({ date: props.date, amount, type }));
  }

  function typeClick(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      if (e.target.value === "duration") {
        setType("duration");
      } else {
        setType("installment");
      }
    }
  }

  return (
    <div className="paymentChangeRow">
      <div className="form-group amount">
        <label htmlFor="prepaymentAmount">
          <FormattedMessage id="PrepaymentModification.Label" />
        </label>
        <div className="input-group input-group-lg">
          <input
            value={amount}
            id="prepaymentAmount"
            type="number"
            className="form-control"
            placeholder="1000000..."
            aria-label={intl.formatMessage({
              id: "PrepaymentModification.Label",
            })}
            min="1"
            step="1"
            max={principal}
            onChange={change}
          />
          {lang === "hu" && (
            <div className="input-group-append">
              <span className="input-group-text">Ft</span>
            </div>
          )}
        </div>
      </div>
      <div className="form-group amountSlider">
        <input
          value={amount}
          className="form-control"
          type="range"
          min="1"
          max={principal}
          step="1"
          style={{ width: "100%" }}
          onChange={change}
        />
      </div>
      <div className="form-group amountSlider">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="installmentDecrease"
            name="type"
            value="installment"
            onChange={typeClick}
            checked={type === "installment"}
          />
          <label className="form-check-label" htmlFor="installmentDecrease">
            <FormattedMessage id="PrepaymentModification.DecreasePayment" />
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="durationDecrease"
            name="type"
            value="duration"
            onChange={typeClick}
            checked={type === "duration"}
          />
          <label className="form-check-label" htmlFor="durationDecrease">
            <FormattedMessage id="PrepaymentModification.DecreaseTerm" />
          </label>
        </div>
      </div>
      <div className="buttons">
        <div>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={props.hide}
          >
            <FormattedMessage id="Common.Cancel" />
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={addPrepayment}
          >
            <FormattedMessage id="Common.Save" />
          </button>
        </div>
      </div>
    </div>
  );
}
