import cl from "classnames";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { langSelector, paymentsSelector } from "../selector";
import { deletePrepayment, deleteRate, useAppDispatch, useAppSelector } from "../store";
import { formatCurrency, PERCENT_FORMAT } from "../utils";
import FullCenteredRow from "./FullCenteredRow";
import PrepaymentModification from "./PrepaymentModification";
import RateChange from "./RateChange";

export default function PaymentTable() {
  const payments = useAppSelector(paymentsSelector);
  const [rateChangeDate, setRateChangeDate] = useState<string>();
  const [prepaymentDate, setPrepaymentDate] = useState<string>();
  const dispatch = useAppDispatch();
  const lang = useAppSelector(langSelector);
  const intl = useIntl();

  function showRateChangeModal(date: string) {
    setRateChangeDate(date);
    setPrepaymentDate(undefined);
  }

  function showPrepaymentModal(date: string) {
    setPrepaymentDate(date);
    setRateChangeDate(undefined);
  }

  function delPrepayment(date: string) {
    dispatch(deletePrepayment(date));
  }

  function delRateChange(date: string) {
    dispatch(deleteRate(date));
  }

  return (
    <FullCenteredRow cols={8} center={false}>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col"><FormattedMessage id="PaymentTable.Date" /></th>
              <th scope="col"><FormattedMessage id="PaymentTable.Payment" /></th>
              <th scope="col"><FormattedMessage id="PaymentTable.Principal" /></th>
              <th scope="col"><FormattedMessage id="PaymentTable.Interest" /></th>
              <th scope="col"><FormattedMessage id="PaymentTable.Balance" /></th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((p) => (
              <React.Fragment key={p.date + p.type}>
                <tr className={cl(p.class)}>
                  {p.type === "rate" && (
                    <>
                      <th scope="row" className="text-left">
                        {p.date}
                      </th>
                      <td
                        colSpan={4}
                        title={intl.formatMessage({id: "PaymentTable.InterestRateChange"})}
                        className={cl(p.class)}
                      >
                        <strong>
                          <FormattedMessage id="PaymentTable.InterestRate" />
                          {PERCENT_FORMAT.format((p?.rate ?? 0) / 100)}
                        </strong>
                      </td>

                      <td className="text-start">
                        <span
                          className="h5"
                          title={intl.formatMessage({id: "PaymentTable.EditRateChange"})}
                          onClick={() => setRateChangeDate(p.date)}
                          style={{ cursor: "pointer" }}
                        >
                          ✏️
                        </span>
                        &nbsp;
                        <span
                          className="h5"
                          title={intl.formatMessage({id: "PaymentTable.RemoveRateChange"})}
                          onClick={() => delRateChange(p.date)}
                          style={{ cursor: "pointer" }}
                        >
                          ❌
                        </span>
                      </td>
                    </>
                  )}
                  {p.type === "prepayment" && (
                    <>
                      <th scope="row" className="text-left">
                        {p.date}
                      </th>
                      <td
                        colSpan={4}
                        title={intl.formatMessage({id: "PaymentTable.Prepayment"})}
                        className={cl(p.class)}
                      >
                        <strong>
                          <FormattedMessage id="PaymentTable.Prepayment" />: {formatCurrency(lang, p?.amount ?? 0) }
                        </strong>
                      </td>
                      <td className="text-start">
                        <span
                          className="h5"
                          title={intl.formatMessage({id: "PaymentTable.EditPrepayment"})}
                          onClick={() => setPrepaymentDate(p.date)}
                          style={{ cursor: "pointer" }}
                        >
                          ✏️
                        </span>
                        &nbsp;
                        <span
                          className="h5"
                          title={intl.formatMessage({id: "PaymentTable.RemovePrepayment"})}
                          onClick={() => delPrepayment(p.date)}
                          style={{ cursor: "pointer" }}
                        >
                          ❌
                        </span>
                      </td>
                    </>
                  )}
                  {p.type === "payment" && (
                    <>
                      <th scope="row" className="text-left">
                        {p.date}
                      </th>
                      <td title={intl.formatMessage({id: "PaymentTable.Payment"})}>
                        {formatCurrency(lang, p?.installment ?? 0)}
                      </td>
                      <td title={intl.formatMessage({id: "PaymentTable.Principal"})}>
                        {formatCurrency(lang, p?.principalPayment ?? 0)}
                      </td>
                      <td title={intl.formatMessage({id: "PaymentTable.Interest"})}>
                        {formatCurrency(lang, p?.interestPayment ?? 0)}
                      </td>
                      <td title={intl.formatMessage({id: "PaymentTable.Balance"})}>
                        {formatCurrency(lang, p?.principal ?? 0)}
                      </td>
                      <td>
                        <span
                          className="h4"
                          title={intl.formatMessage({id: "PaymentTable.Prepayment"})}
                          onClick={() => showPrepaymentModal(p.date)}
                          style={{ cursor: "pointer" }}
                        >
                          💰
                        </span>
                        &nbsp;
                        {!p.firstRow && (
                          <span
                            className="h4"
                            title={intl.formatMessage({id: "PaymentTable.InterestRateChange"})}
                            onClick={() => showRateChangeModal(p.date)}
                            style={{ cursor: "pointer" }}
                          >
                            📈
                          </span>
                        )}
                      </td>
                    </>
                  )}
                </tr>
                {prepaymentDate === p.date && p.type === "payment" && (
                  <tr>
                    <td colSpan={6} style={{ textAlign: "center" }}>
                      <PrepaymentModification
                        key={p.date + "prepayment"}
                        date={p.date}
                        hide={() => setPrepaymentDate(undefined)}
                      />
                    </td>
                  </tr>
                )}
                {rateChangeDate === p.date && p.type === "payment" && (
                  <tr>
                    <td colSpan={6} style={{ textAlign: "center" }}>
                      <RateChange
                        key={p.date + "rateChange"}
                        date={p.date}
                        hide={() => setRateChangeDate(undefined)}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </FullCenteredRow>
  );
}
