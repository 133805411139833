import { intlFormat } from "date-fns";
import * as React from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { changeDuration, useAppDispatch, useAppSelector } from "../store";
import PinnableInput from "./PinnableInput";

export interface Props {}

export default function DurationInput(props: Props) {
  const duration = useAppSelector((state) => state.duration);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  function change(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(changeDuration(Number(e.target.value)));
  }

  return (
    <PinnableInput
      label={intl.formatMessage({ id: "DurationInput.Term" })}
      inputId="duration"
    >
      <input
        value={duration}
        id="duration"
        type="number"
        className="form-control"
        placeholder="60, 120..."
        aria-label={intl.formatMessage({ id: "DurationInput.Term" })}
        min="1"
        onChange={change}
      />
      <div className="input-group-append">
        <span className="input-group-text"><FormattedMessage id="DurationInput.Month"/></span>
      </div>
      <input
        value={duration}
        className="form-control"
        type="range"
        min="6"
        max="300"
        style={{ width: "100%" }}
        onChange={change}
      />
    </PinnableInput>
  );
}
