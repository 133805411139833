import * as React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import FullCenteredRow from "./FullCenteredRow";
import { Target, setTarget, useAppDispatch, useAppSelector } from "../store";

export interface Props {
  label: string;
  inputId: Target;
  info?: string;
  children: React.ReactNode;
}

export default function PinnableInput({
  label,
  inputId,
  info,
  children,
}: Props) {
  const target = useAppSelector((state) => state.target === inputId);
  const [pinned, setPinned] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();

  return (
    <FullCenteredRow cols={4} center={false} pinned={pinned}>
      <div className="form-group">
        <label htmlFor={inputId}>{label}</label>
        <span
          className="float-right"
          onClick={() => setPinned((p) => !p)}
          style={{ cursor: "pointer" }}
          title={intl.formatMessage({ id: "PinnableInput.Title" })}
        >
          {pinned ? "❌" : "📌"}
        </span>
        <span
          className="float-right"
          onClick={() => dispatch(setTarget(inputId))}
          style={{ cursor: "pointer", marginRight: "1em" }}
          title={intl.formatMessage({ id: "PinnableInput.Target" })}
        >
          {target ? "🎯" : "⭕"}
        </span>
        <div className="input-group input-group-lg">{children}</div>
        {!pinned && <small className="text-muted">{info}</small>}
      </div>
    </FullCenteredRow>
  );
}
