import * as React from "react";
import AmountInput from "./AmountInput";
import DurationInput from "./DurationInput";
import InstallmentInput from "./InstallmentInput";
import RateInput from "./RateInput";

export default function MainData() {
  return (
    <div>
      <AmountInput />
      <DurationInput />
      <RateInput />
      <InstallmentInput />
    </div>
  );
}
