import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../store";
import FullCenteredRow from "./FullCenteredRow";

export default function Feedback() {
  const maillink = "mailto:" + atob("Y3NhYmEuZGF2aWRAY3N1emRpLmh1");
  const lang = useAppSelector((state) => state.lang);

  return (
    <FullCenteredRow>
      <a href={maillink}>
        <FormattedMessage id="Feedback.Title" />
      </a>
      &nbsp;|&nbsp;
      {lang === "hu" && (
        <a href="https://netto.csuzdi.hu" target="_blank">
          Nettó bér kalkulátor
        </a>
      )}
      &nbsp;|&nbsp;
      <a href="https://ido.csuzdi.hu" target="_blank">
        <FormattedMessage id="Feedback.DateTimeToolbox" />
      </a>
      &nbsp;|&nbsp;
      <div
        className="fb-share-button"
        data-href="https://hitel.csuzdi.hu"
        data-layout="button"
        data-size="small"
        data-mobile-iframe="false"
      >
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fhitel.csuzdi.hu%2F&amp;src=sdkpreparse"
          className="fb-xfbml-parse-ignore"
          rel="noreferrer noopener"
        >
          Share
        </a>
      </div>
    </FullCenteredRow>
  );
}
