import * as React from "react";
import { useIntl } from "react-intl";
import { rateSelector } from "../selector";
import { changeInitialRate, useAppDispatch, useAppSelector } from "../store";
import PinnableInput from "./PinnableInput";

export interface Props {}

export default function RateInput(props: Props) {
  const rate = useAppSelector(rateSelector);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  function change(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(changeInitialRate(Number(e.target.value)));
  }

  return (
    <PinnableInput
      label={intl.formatMessage({ id: "RateInput.Rate" })}
      inputId="rate"
      info={intl.formatMessage({ id: "RateInput.Info" })}
    >
      <input
        value={rate}
        id="rate"
        type="number"
        className="form-control"
        placeholder="5,25..."
        aria-label="Kamatláb"
        min="0"
        step="0.01"
        onChange={change}
      />
      <div className="input-group-append">
        <span className="input-group-text">%</span>
      </div>
      <input
        value={rate}
        className="form-control"
        type="range"
        min="0"
        max="40"
        step="0.01"
        style={{ width: "100%" }}
        onChange={change}
      />
    </PinnableInput>
  );
}
