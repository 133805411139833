import * as React from "react";
import { FormattedMessage } from "react-intl";
import { firstDateSelector } from "../selector";
import { changeStartDate, useAppDispatch, useAppSelector } from "../store";
import FullCenteredRow from "./FullCenteredRow";

export interface Props {}

export default function FirstPayDate(props: Props) {
  const firstPaymentDate = useAppSelector(firstDateSelector);
  const dispatch = useAppDispatch();

  return (
    <FullCenteredRow cols={4} center={false} className="mt-4">
      <div className="form-group">
        <label htmlFor="firstPaymentDate">
          <FormattedMessage id="FirstPayDate.StartDate"/>
        </label>
        <div className="input-group input-group-lg">
          <input
            id="firstPaymentDate"
            value={firstPaymentDate}
            className="form-control"
            type="date"
            style={{width: "100%"}}
            onChange={(e) => dispatch(changeStartDate(e.target.value))}
          />
        </div>
      </div>
    </FullCenteredRow>
  );
}
