import * as React from "react";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { interestRatesSelector } from "../selector";
import { changeRate, useAppDispatch, useAppSelector } from "../store";
import { getRate } from "../utils";

export interface Props {
  date: string;
  hide: () => void;
}

export default function RateChange(props: Props) {
  const rates = useAppSelector(interestRatesSelector);
  const lastRate = useMemo(
    () =>
      rates.find((r) => r.date === props.date) ?? getRate(rates, props.date),
    [rates, props.date]
  );
  const [rate, setRate] = useState<number>(lastRate.rate);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  function change(e: React.ChangeEvent<HTMLInputElement>) {
    setRate(Number(e.target.value));
  }

  function setNewRate() {
    props.hide();
    dispatch(changeRate({ date: props.date, rate }));
  }

  return (
    <div className="paymentChangeRow">
      <div className="form-group amount">
        <label htmlFor="newRate">
          <FormattedMessage id="RateChange.NewRate" />
        </label>
        <div className="input-group input-group-lg">
          <input
            value={rate}
            id="newRate"
            type="number"
            className="form-control"
            aria-label={intl.formatMessage({ id: "RateChange.NewRate" })}
            min="0.01"
            step="0.01"
            max={40}
            onChange={change}
          />
          <div className="input-group-append">
            <span className="input-group-text">%</span>
          </div>
        </div>
      </div>
      <div className="form-group amountSlider">
        <input
          value={rate}
          className="form-control"
          type="range"
          min="0.01"
          max={40}
          step="0.01"
          style={{ width: "100%" }}
          onChange={change}
        />
      </div>
      <div className="buttons">
        <div>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={props.hide}
          >
            <FormattedMessage id="Common.Cancel" />
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={setNewRate}
          >
            <FormattedMessage id="Common.Save" />
          </button>
        </div>
      </div>
    </div>
  );
}
